.m3-datepicker-calendar-paper .MuiPickersDay-root.Mui-selected {
  background-color: var(--md-ref-palette-primary40);
}
.m3-datepicker-calendar-paper .MuiTypography-caption {
  font-weight: 700;
}
.m3-datepicker-calendar-paper .PrivatePickersYear-yearButton.Mui-selected {
  background-color: var(--md-ref-palette-primary40);
}
.m3-datepicker-calendar-paper
  .PrivatePickersYear-yearButton.Mui-selected:hover {
  background-color: var(--md-ref-palette-primary50);
}
