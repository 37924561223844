.SnackbarContainer-root .SnackbarContent-root {
  color: var(--md-ref-palette-neutral95);
  background-color: var(--md-ref-palette-neutral20);
}
.SnackbarContainer-root .snackbar-action-button {
  width: initial;
  font-size: 12px;
  min-width: initial;
  color: var(--md-ref-palette-primary80);
}
.SnackbarContainer-root .snackbar-action-button:hover {
  background-color: transparent;
}

/** Dark Theme */
.dark-theme .SnackbarContainer-root .SnackbarContent-root {
  color: var(--md-ref-palette-neutral20);
  background-color: var(--md-ref-palette-neutral90);
}
.dark-theme .SnackbarContainer-root .snackbar-action-button {
  color: var(--md-ref-palette-primary40);
}
