.m3-button-link {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: var(--md-ref-palette-secondary40);
}

.m3-button-link:hover {
  text-decoration: underline;
}

.dark-theme .m3-button-link {
  color: var(--md-ref-palette-secondary80);
}
