.timeline-gauge-line {
  width: 0;
  height: 8px;
  position: relative;
}

.timeline-gauge-line:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  content: '';
  opacity: 0.2;
  position: absolute;
  background-color: var(--md-ref-palette-neutral20);
}

.timeline-gauge-small-line {
  height: 6px;
}
.timeline-gauge-small-line:before {
  width: 1px;
  opacity: 0.3;
}

.timeline-gauge-label {
  left: -10px;
  width: 69px;
  bottom: -20px;
  font-weight: 500;
  position: absolute;
}

.dark-theme .timeline-gauge-line:before {
  background-color: var(--md-ref-palette-neutral90);
}
