.autocomplete-multiple-item-selected
  + li:not(.autocomplete-multiple-item-selected) {
  position: relative;
}
.autocomplete-multiple-item-selected
  + li:not(.autocomplete-multiple-item-selected):before {
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  position: absolute;
  border-top: 2px solid var(--md-ref-palette-neutral90);
}

.dark-theme
  .autocomplete-multiple-item-selected
  + li:not(.autocomplete-multiple-item-selected):before {
  border-color: var(--md-ref-palette-neutral20);
}
