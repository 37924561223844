.tooltip-box {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1;
  position: absolute;
}

.tooltip-duration {
  font-size: 14px;
  font-weight: 500;
}

.tooltip-task-name {
  opacity: 0.8;
}

.tooltip-start-end {
  opacity: 0.6;
}
